
.btn-group-toggle{
  width: 88px;
  padding: 5px;
  height: 34px;
  display: flex;
  align-items: center;
  border: 1px solid #c6dcf5;
  box-shadow: 0px 1px 5px rgba(3,115,168,0.2);
  background-color: #ffffff;
  border-radius: 4px;
  .btn-item {
    background-color: #ffffff;
    border-radius: 5px;
    padding: 0!important;
    width: 27px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.active{
      background-color: rgba(3, 115, 168, 0.2) !important;
    }
    .icon{
      width: 15px;
      height: 14px;

    }
  }
  &.disabled{
    background-color: #E8EEF2;
  }



}
